import { Directive, ElementRef, Renderer2, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[kenjoBoldText]'
})
export class BoldTextDirective implements OnChanges {
  @Input() kenjoBoldText!: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['kenjoBoldText']) {
      this.updateContent();
    }
  }

  private updateContent() {
    const modifiedContent = this.kenjoBoldText?.replace(/<<([^<>]+)>>/g, '<b>$1</b>') || '';
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', modifiedContent);
  }
}