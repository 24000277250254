import { environment } from '@env';
import { Injectable } from '@angular/core';

import {
  IUserAttendance,
} from '../model/user-attendance.model';
import { HttpClientService } from '@app/shared/services/http-client.service';
import { AuthService } from '@app/shared/services/auth.service';
import { ErrorService } from '@app/shared/services/error.service';

@Injectable({
  providedIn: 'root',
})
export class UserAttendanceService {
  constructor(
    private http: HttpClientService,
    private auth: AuthService,
    private errorService: ErrorService
  ) {}

  async updateAttendanceEntry(
    entry: IUserAttendance,
    body: IUserAttendance,
    profileKey: string
  ) {
    try {
      await this.http.put(
        `${environment.PEOPLE_CLOUD_APP_URL}/user-attendance-db/${entry._id}`,
        { ...body, profileKey, autoDeductBreak: true },
        this.auth.getAuthHeader()
      );
    } catch (error) {
      this.errorService.displayError(error?.error);
    }
  }
}
