import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'kenjo-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss']
})
export class PrimaryButtonComponent {
  @Input() title: string;
  @Input() buttonId?: string = 'submit-button';
  @Input() flexWidth: boolean = false;
  @Input() size: 'huge' | 'large' | 'medium' = 'large';

  @Input() loading: boolean = false;
  @Input() buttonStatus: 'ready' | 'disabled' | 'failed' | 'success' | 'neutral' = 'ready';
  @Output() onClick = new EventEmitter<void>();

  emitClick() {
    if (this.buttonStatus !== 'disabled' && this.loading === false) {
      this.onClick.emit();
    }
  }
}
