import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DatetimeComponent } from './components/datetime/datetime.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModule } from '../material.module';
import { IconComponent } from './components/icon/icon.component';
import { BackArrowButtonComponent } from './components/back-arrow-button/back-arrow-button.component';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslatePipe } from './pipes/translate.pipe';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { MatRippleModule } from '@angular/material/core';
import { FooterBarComponent } from './components/footer-bar/footer-bar.component';
import { RefreshButtonComponent } from './components/refresh-button/refresh-button.component';
import { CodeInputComponent } from './components/code-input/code-input.component';
import { EmptyLocationComponent } from './components/empty-location/empty-location.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslateWithDataPipe } from './pipes/translate-with-data.pipe';
import { RaisedButtonComponent } from './components/raised-button/raised-button.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { HoldButtonComponent } from './components/hold-button/hold-button.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SuccessScreenContainerComponent } from './components/success-screen-container/success-screen-container.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { HourPipe } from './pipes/hour.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { ErrorIllustrationComponent } from './illustrations/error-illustration.component';
import { SuccessIllustrationComponent } from './illustrations/success-illustration.component';
import { HoldableDirective } from './directives/holdable.directive';
import { MountainIllustrationComponent } from './illustrations/mountain-illustration.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LoggedOutComponentIllustration } from './illustrations/logged-out-illustration.component';
import { SecondaryButtonComponent } from './components/secondary-button/secondary-button.component';
import { CompactDurationPipe } from './pipes/compact-duration.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { AutoDeductDialogComponent } from './components/auto-deduct-break-dialog/auto-deduct-dialog.component';
import { ColumnContainerComponent } from './components/column-container/column-container.component';
import { ColumnComponent } from './components/column/column.component';
import { TransactionalDialogComponent } from './components/transactional-dialog/transactional-dialog.component';
import { BoldTextDirective } from '@shared/directives/bold-text.directive';

@NgModule({
  declarations: [
    SidebarComponent,
    DatetimeComponent,
    ProgressBarComponent,
    HeaderBarComponent,
    IconComponent,
    BackArrowButtonComponent,
    LanguagePickerComponent,
    TranslatePipe,
    PrimaryButtonComponent,
    FooterBarComponent,
    RefreshButtonComponent,
    ErrorIllustrationComponent,
    CodeInputComponent,
    EmptyLocationComponent,
    ConfirmationDialogComponent,
    DialogContainerComponent,
    TranslateWithDataPipe,
    RaisedButtonComponent,
    UserAvatarComponent,
    HoldButtonComponent,
    SuccessScreenContainerComponent,
    SuccessIllustrationComponent,
    HourPipe,
    DurationPipe,
    HoldableDirective,
    ClickOutsideDirective,
    BoldTextDirective,
    MountainIllustrationComponent,
    LoadingSpinnerComponent,
    LoggedOutComponentIllustration,
    SecondaryButtonComponent,
    CompactDurationPipe,
    AutoDeductDialogComponent,
    ColumnContainerComponent,
    ColumnComponent,
    TransactionalDialogComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MaterialModule,
    FormsModule.withConfig({
      callSetDisabledState: 'always'
    }),
    MatSelectModule,
    HttpClientModule,
    MatRippleModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    OverlayModule,
  ],
  exports: [
    SidebarComponent,
    DatetimeComponent,
    ProgressBarComponent,
    HeaderBarComponent,
    IconComponent,
    TranslatePipe,
    TranslateWithDataPipe,
    HourPipe,
    DurationPipe,
    PrimaryButtonComponent,
    FooterBarComponent,
    ErrorIllustrationComponent,
    CodeInputComponent,
    EmptyLocationComponent,
    ConfirmationDialogComponent,
    RaisedButtonComponent,
    UserAvatarComponent,
    SuccessScreenContainerComponent,
    SuccessIllustrationComponent,
    MountainIllustrationComponent,
    HoldButtonComponent,
    LoadingSpinnerComponent,
    LoggedOutComponentIllustration,
    DialogContainerComponent,
    ClickOutsideDirective,
    BoldTextDirective,
    TransactionalDialogComponent,
  ],
  providers: [TranslatePipe, TranslateWithDataPipe, HourPipe],
})
export class SharedModule {}
